import React from "react";
import {
  EthereumWallet,
  useEthereumWallet,
} from "../hooks/ethereum/useEthereumWallet";

interface IWalletContext extends EthereumWallet {}

export const WalletContext = React.createContext<IWalletContext>({
  account: "",
  accountChangePending: false,
  balance: "",
  connect: (isMetaMask?: boolean) => {
    return;
  },
  disconnect: () => {
    return;
  },
  ethereum: {} as any,
  insufficientFunds: false,
  isWrongNetwork: false,
  switchNetwork: () => {
    return;
  },
  // Ignoring this until we get the full result type for type definition
  // @ts-ignore
  sign: (account: string | undefined, msg: string, pass: string) => {
    return new Promise(() => {});
  },
});

export const WalletProvider: React.FC = ({ children }) => {
  const wallet = useEthereumWallet();
  return (
    <WalletContext.Provider value={{ ...wallet }}>
      {children}
    </WalletContext.Provider>
  );
};
