import { useState } from "react";

enum AppModals {
  walletModal = "walletModal",
  noReceiptsModal = "noReceiptsModal",
  transactionPendingModal = "transactionPendingModal",
  transactionSuccessful = "transactionPendingModal",
}

export type ModalController = {
  [Name in AppModals]: {
    isOpen: boolean;
    onOpen: (tabIndex?: number) => void;
    onClose: () => void;
  };
} & {
  mainWalletTabIndex: number;
};

export const useModalController = (): ModalController => {
  const [walletModalIsOpen, setWalletModalIsOpen] = useState<boolean>(false);

  const [noReceiptsModalIsOpen, setNoReceiptsModalIsOpen] =
    useState<boolean>(false);

  const [transactionPendingModalIsOpen, setTransactionPendingModalIsOpen] =
    useState<boolean>(false);

  const [mainWalletTabIndex, setMainWalletIndex] = useState<number>(0);

  return {
    walletModal: {
      isOpen: walletModalIsOpen,
      onOpen: (tabIndex?: number) => {
        setWalletModalIsOpen(true);
        setMainWalletIndex(tabIndex || 0);
      },
      onClose: () => setWalletModalIsOpen(false),
    },
    noReceiptsModal: {
      isOpen: noReceiptsModalIsOpen,
      onOpen: (tabIndex?: number, collectionId?: string) => {
        setNoReceiptsModalIsOpen(true);
      },
      onClose: () => setNoReceiptsModalIsOpen(false),
    },
    transactionPendingModal: {
      isOpen: transactionPendingModalIsOpen,
      onOpen: () => {
        setTransactionPendingModalIsOpen(true);
      },
      onClose: () => setTransactionPendingModalIsOpen(false),
    },
    mainWalletTabIndex,
  };
};
