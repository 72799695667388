import { useState } from "react";
import Minting from "./WalletContent/Minting";
import { WalletProps } from "./types";
import Indicator from "./Indicator";
import WrongNetwork from "./WalletContent/WrongNetwork";
import Error from "./WalletContent/Error";
import Account from "./Account";
import Success from "./WalletContent/Success";

function Wallet({
  isOpen,
  onClose,
  handleConnect,
  isAccountModal,
  indicatorType,
  handleDisconnect,
  mainWalletTabIndex,
  account,
}: WalletProps) {
  const [isClosing, setIsClosing] = useState(false);
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 600);
  };

  const renderIndicator = () => {
    switch (indicatorType) {
      case "ERROR":
        return <Error closeModal={onClose} />;
      case "MINTING":
        return <Minting />;
      case "WRONG_NETWORK":
        return <WrongNetwork closeModal={onClose} />;
      case "SUCCESS":
        return <Success closeModal={onClose} />;
    }
  };

  return isAccountModal ? (
    <Account
      handleDisconnect={handleDisconnect}
      mainWalletTabIndex={mainWalletTabIndex}
      account={account}
      isClosing={isClosing}
      closeModal={closeModal}
      isOpen={isOpen}
      handleConnect={handleConnect}
      onClose={onClose}
    />
  ) : (
    <Indicator
      withBackgroundImage={
        indicatorType === "MINTING" || indicatorType === "SUCCESS"
      }
      indicatorType={indicatorType}
      isClosing={isClosing}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {renderIndicator()}
    </Indicator>
  );
}

export default Wallet;
