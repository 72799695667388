import { ethers } from "ethers";
import React from "react";
import { WalletContext } from "../../context/WalletContext";
import { Web3Provider } from "@ethersproject/providers";
import { EthereumRequests } from "./ethereumRequests";

export type Contract = {
  contract: ethers.Contract | undefined;
  hasReceipt: boolean;
  mintToken: () => any;
  preMint: (amount: number) => void;
  transactionPending: boolean;
  transactionSuccessful: boolean;
  transactionUnSuccessful: boolean;
  resetTransactionState: () => void;
  tokenIds: number[];
  enableMinting: () => void;
  saleIsActive: boolean;
  tokenBalancePending: boolean;
  currentTokenBalance: number;
  withDrawEth: (ethereum: Web3Provider, address: string) => void;
  getTotalSupply: () => void;
  totalSupply: number;
  revealCollection: (uri: string) => void;
  baseUri: string;
  setProvenance: (provenanceHash: string) => void;
  provenance: string;
};

export const useContract = (address?: string) => {
  // const { ethereum, account, isWrongNetwork } = React.useContext(WalletContext);
  const { account, isWrongNetwork } = React.useContext(WalletContext);

  const [contract] = React.useState<ethers.Contract>();
  const [currentTokenBalance, setCurrentTokenBalance] = React.useState<number>(
    undefined as unknown as number
  );
  const [totalSupply, setTotalSupply] = React.useState<number>(
    undefined as unknown as number
  );
  const [transactionPending, setTransactionPending] = React.useState(false);
  const [transactionSuccessful, setTransactionSuccessful] =
    React.useState(false);
  const [transactionUnSuccessful, setTransactionUnSuccessful] =
    React.useState(false);
  const [tokenIds, setTokenIds] = React.useState<number[]>([]);
  const [saleIsActive, setSaleIsActive] = React.useState<boolean>(false);
  const [currentBaseUri, setCurrentBaseUri] = React.useState<string>(
    undefined as unknown as string
  );
  const [currentProvenance, setCurrentProvenance] = React.useState<string>(
    undefined as unknown as string
  );

  const [tokenBalancePending, setTokenBalancePending] =
    React.useState<boolean>(false);

  const getTokenBalance = async (
    contract: ethers.Contract,
    account: string
  ) => {
    setTokenBalancePending(true);
    if (contract && account && !isWrongNetwork)
      await contract
        .balanceOf(account)
        .then((b: any) => {
          setCurrentTokenBalance(b.toNumber());
        })
        .catch((err: any) => console.log(err));
  };

  const getTotalSupply = async () => {
    if (contract) {
      await contract.totalSupply().then((b: any) => {
        setTotalSupply(b.toNumber());
      });
    }
  };

  const getBaseUri = async (contract: ethers.Contract) => {
    if (contract)
      await contract
        .baseURI()
        .then((b: any) => {
          setCurrentBaseUri(b);
        })
        .catch((err: any) => console.log(err));
  };

  const getProvenance = async () => {
    if (contract)
      await contract
        .PROVENANCE()
        .then((p: any) => {
          setCurrentProvenance(p);
        })
        .catch((err: any) => console.log(err));
  };

  const getReceipts = async (
    contract: ethers.Contract,
    tokenBalance: number
  ) => {
    setTokenBalancePending(true);

    if (tokenBalance && account) {
      let ids: number[] = [];
      for (let i = 0; i < tokenBalance; i++) {
        await contract
          .tokenOfOwnerByIndex(account, i)
          .then((id: any) => ids.push(id.toNumber()))
          .then(() => setTokenIds(ids))
          .catch((err: any) => {
            setTokenIds([]);
          });
      }
    }
    setTokenBalancePending(false);
  };

  const mintingEnabled = async (contract: ethers.Contract) => {
    const enabled = await contract?.saleIsActive();
    setSaleIsActive(enabled);
  };

  const resetTransactionState = () => {
    setTransactionSuccessful(false);
    setTransactionUnSuccessful(false);
  };

  // React.useEffect(() => {
  //   if (ethereum && account && !isWrongNetwork) {
  //     const contract =
  //       ethereum &&
  //       new ethers.Contract(
  //         address || "",
  //         // address || MASATOSHI_CONTRACT_ADDRESS,
  //         masterchefMasatoshi_json["abi"],
  //         ethereum.getSigner(account)
  //       );
  //     setContract(contract);
  //     getTokenBalance(contract, account);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ethereum, account, isWrongNetwork]);

  React.useEffect(() => {
    if (transactionSuccessful && contract && account) {
      getTokenBalance(contract, account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSuccessful, contract, account]);

  React.useEffect(() => {
    if (contract) {
      getReceipts(contract, currentTokenBalance);

      if (
        contract.address !==
        // MASATOSHI_CONTRACT_ADDRESS
        ""
      ) {
        mintingEnabled(contract);
        getTotalSupply();
        getBaseUri(contract);
        getProvenance();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTokenBalance, contract]);

  const mintToken = async () => {
    setTransactionPending(true);
    setTokenBalancePending(true);
    await contract
      ?.mintTokens(1)
      .then(() => {
        let tokenBalance: number;

        const pollTransactionState = setInterval(() => {
          contract?.balanceOf(account).then((b: any) => {
            tokenBalance = b.toNumber();
          });
          if (tokenBalance === currentTokenBalance + 1) {
            clearInterval(pollTransactionState);
            setCurrentTokenBalance(tokenBalance);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch(() => {
        setTransactionUnSuccessful(true);
        setTransactionPending(false);
      });
  };

  const preMint = async (amount: number) => {
    setTransactionPending(true);
    await contract
      ?.preMint(amount)
      .then(() => {
        setTransactionSuccessful(true);
        let tokenBalance: number;

        const pollTransactionState = setInterval(() => {
          contract?.balanceOf(account).then((b: any) => {
            tokenBalance = b.toNumber();
          });

          if (tokenBalance === currentTokenBalance + 1) {
            clearInterval(pollTransactionState);
            setCurrentTokenBalance(tokenBalance);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: any) => {
        console.error(err);
        setTransactionUnSuccessful(true);
        setTransactionPending(false);
      });
  };

  const enableMinting = async () => {
    setTransactionPending(true);
    await contract
      ?.flipSaleState()
      .then(() => {
        let saleState: boolean;

        const pollSaleState = setInterval(() => {
          contract?.saleIsActive().then((s: boolean) => (saleState = s));
          if (saleState === !saleIsActive) {
            clearInterval(pollSaleState);
            setSaleIsActive(saleState);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: any) => {
        console.error(err);
        setTransactionPending(false);
      });
  };

  const revealCollection = async (baseUri: string) => {
    setTransactionPending(true);
    await contract
      ?.setBaseURI(baseUri)
      .then(() => {
        let baseUri: string;

        const pollBaseUriState = setInterval(() => {
          contract?.baseURI().then((uri: string) => (baseUri = uri));
          if (!!baseUri) {
            clearInterval(pollBaseUriState);
            setCurrentBaseUri(baseUri);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err);
        setTransactionPending(false);
      });
  };

  const withDrawEth = async (ethereum: Web3Provider, address: string) => {
    setTransactionPending(true);
    await contract
      ?.withdraw()
      .then(() => {
        let balance: number;

        const pollSaleState = setInterval(() => {
          const requests = new EthereumRequests(ethereum);
          requests.getBalance(address).then((b) => {
            const value = parseInt(b) / Math.pow(10, 18) || 0;
            balance = value;
          });
          if (balance === 0) {
            clearInterval(pollSaleState);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err);
        setTransactionPending(false);
      });
  };

  const setProvenance = async (provenanceHash: string) => {
    setTransactionPending(true);
    await contract
      ?.setProvenanceHash(provenanceHash)
      .then(() => {
        let provenance: string;

        const pollProvenanceState = setInterval(() => {
          contract.PROVENANCE().then((p: any) => {
            provenance = p;
          });
          if (!!provenance) {
            clearInterval(pollProvenanceState);
            setCurrentProvenance(provenance);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err);
        setTransactionPending(false);
      });
  };

  return {
    contract,
    hasReceipt: !!currentTokenBalance,
    mintToken,
    preMint,
    transactionPending,
    transactionSuccessful,
    transactionUnSuccessful,
    resetTransactionState,
    tokenIds,
    enableMinting,
    saleIsActive,
    tokenBalancePending,
    currentTokenBalance,
    getTokenBalance,
    withDrawEth,
    getTotalSupply,
    totalSupply,
    revealCollection,
    baseUri: currentBaseUri,
    setProvenance,
    provenance: currentProvenance,
  };
};
