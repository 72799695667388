import TextButton from "components/atoms/buttons/TextButton";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "tools/context/ModalContext";
import AppLogo from "../atoms/buttons/AppLogo";
import ConnectWallet from "../atoms/buttons/ConnectWallet";
import SocialButton from "../atoms/buttons/SocialButton";

function Header() {
  const navigate = useNavigate();
  const { walletModal } = useContext(ModalContext);

  return (
    <div className="bg-black h-12 lg:h-16 px-6 md:pl-16 md:pr-10 sticky top-0 flex flex-row items-center justify-between z-50">
      <AppLogo onClick={() => navigate("/")} />
      <div className="flex-row justify-between w-1/3 hidden lg:flex">
        <TextButton name="Passport" />
        <TextButton name="Traits" />
        <TextButton name="Game" />
        <TextButton name="Roadmap" />
        <TextButton name="Team" />
      </div>
      <div className="flex-row flex">
        <ConnectWallet onClick={() => walletModal.onOpen()} />
        <SocialButton name="twitter" classname="mx-5 hidden md:block" />
        <SocialButton name="discord" classname="hidden md:block" />
      </div>
    </div>
  );
}

export default Header;
