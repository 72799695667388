import LogoGreen from "assets/images/logo-green.png";
import Twitter from "assets/images/twitter.png";
import Discord from "assets/images/discord.png";
import Barcode from "assets/images/barcode.png";

function Footer() {
  return (
    <div className="bg-[#2e002c] h-[104px] px-5 bottom-0 flex flex-row items-center justify-between lg:h-32 lg:px-[89px]">
      <div className="flex flex-col lg:flex-row lg:items-center justify-center">
        <img className="w-28 lg:w-56" src={LogoGreen} alt="prepacked" />
        <p className="text-[6px] text-[#FF96FB] leading-[9px] lg:leading-5 tracking-[0.5px] lg:tracking-[1.3px] font-bold lg:ml-8 lg:text-[13px] lg:mt-0 mt-2">
          Designed in Quarantine. (No Joke)
          <br />
          No Rights Reserved.
        </p>
      </div>
      <div className="flex flex-row lg:hidden items-start justify-center">
        <img className="w-8 object-contain mr-8" src={Twitter} alt="twitter" />
        <img className="w-8 object-contain" src={Discord} alt="discord" />
      </div>
      <img
        className="w-12 lg:w-[107px] object-contain hidden lg:block"
        src={Barcode}
        alt="barcode"
      />
    </div>
  );
}

export default Footer;
