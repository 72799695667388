import React from "react";
import { ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { EthereumRequests } from "./ethereumRequests";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useStorageState } from "react-storage-hooks";
import usePrevious from "../usePrevious";

const MINT_PRICE = 5;
const INFURA_ID = "6a7282649222432f9191ca853d1daa6b";

declare global {
  interface Window {
    ethereum?: any;
  }
}

export type EthereumWallet = {
  account: string | undefined;
  accountChangePending: boolean;
  balance: string;
  connect: (isMetaMask: boolean) => void;
  disconnect: () => void;
  ethereum: Web3Provider | undefined;
  insufficientFunds: boolean;
  isWrongNetwork: boolean;
  switchNetwork: () => void;
  sign: (
    account: string | undefined,
    msg: string,
    pass: string
  ) => Promise<any>;
};

export const useEthereumWallet = (): EthereumWallet => {
  const [ethereum, setEthereum] = React.useState<Web3Provider>();
  const [provider, setProvider] = React.useState<any>();
  const [currentAccount, setCurrentAccount] = React.useState<string>();
  const [balance, setBalance] = React.useState<number>(0);
  const [isDisconnected, setIsDisconnected] = React.useState<boolean>(false);
  const [accountChangePending, setAccountChangePending] =
    React.useState<boolean>(false);
  const [isWrongNetwork, setIsWrongNetwork] = React.useState(false);
  const [providerName, setProviderName] = useStorageState<
    "metamask" | "walletConnect" | ""
  >(localStorage, "providerName", "");
  const prevAccount = usePrevious(currentAccount);

  const requests = new EthereumRequests(ethereum);

  /**
   * Sets the provider to state if it's available.
   */
  const checkProviderAvailability = async () => {
    if (providerName === "walletConnect") {
      const provider = new WalletConnectProvider({
        infuraId: INFURA_ID,
        pollingInterval: 30000,
      });
      setProvider(provider);
      walletConnectAsProvider();
      const chainId = provider.chainId;
      setCurrentAccount(provider.accounts[0]);
      setAccountChangePending(false);

      if (chainId !== 1) {
        setIsWrongNetwork(false);
        setAccountChangePending(false);
        return;
      }
    } else if (providerName === "metamask" && window.ethereum) {
      setProvider(window.ethereum);
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      const chainId = ethereumProvider?.getNetwork
        ? (await ethereumProvider.getNetwork()).chainId
        : null;
      setEthereum(ethereumProvider);

      // Check if User is already connected by retrieving the accounts
      await ethereumProvider
        .listAccounts()
        .then((a) => {
          // Set User account into state
          setCurrentAccount(a[0]);
        })
        .finally(() => setAccountChangePending(false));

      if (chainId !== 1) {
        setIsWrongNetwork(false);
        setAccountChangePending(false);
        return;
      }
    }
    setCurrentAccount(undefined);
    setAccountChangePending(false);
  };

  /**
   * Handles changes to the current wallet account.
   * Sets the current account to state.
   */
  const handleAccountsChanged = (accounts: any, reConnect?: boolean) => {
    if (!reConnect) {
      if (isDisconnected && accounts[0]) {
        setCurrentAccount(undefined);
        return;
      }
    }

    if (!accounts?.length) {
      setCurrentAccount(undefined);
    } else {
      setCurrentAccount(accounts[0]);
      handleBalance(accounts[0]);
    }
  };

  /**
   * Handles getting current wallet balance.
   */
  const handleBalance = async (account: any) => {
    await requests
      .getBalance(account)
      .then(formatBalance)
      .catch((err: any) => console.log(err));
  };

  /**
   * Sets wallet connect as the provider (not metamask).
   */
  const walletConnectAsProvider = () => {
    const provider = new WalletConnectProvider({
      infuraId: INFURA_ID,
    });
    setEthereum(new ethers.providers.Web3Provider(provider));
    setProvider(provider);
  };

  /**
   * Sets metamask as the provider.
   */
  const metaMaskAsProvider = () => {
    setEthereum(new ethers.providers.Web3Provider(window.ethereum));
    setProvider(window.ethereum);
  };

  /**
   * Connect metamask wallet or another wallet using wallet connect.
   */
  const connect = async (isMetaMask: boolean) => {
    if (!isMetaMask) {
      const newProvider = new WalletConnectProvider({
        infuraId: INFURA_ID,
      });

      await newProvider.enable().catch((err) => console.log(err));
      setEthereum(new ethers.providers.Web3Provider(newProvider));
      setProvider(newProvider);
      setCurrentAccount(newProvider.accounts[0]);
      setProviderName("walletConnect");
      return;
    }

    if (isMetaMask && !window.ethereum) {
      window.open("https://metamask.io/download");
      return;
    }

    if (isMetaMask && window.ethereum) {
      setAccountChangePending(true);
      setIsDisconnected(false);

      metaMaskAsProvider();
      setProviderName("metamask");

      const requests = new EthereumRequests(
        new ethers.providers.Web3Provider(window.ethereum)
      );

      let cancel = false;

      await requests
        .reconnect()
        .catch((err: any) => {
          console.log("fail", err);
          cancel = true;
        })
        .finally(() => {
          setAccountChangePending(false);
        });

      if (cancel) {
        return;
      }

      await requests
        .getAccounts()
        .then((a) => handleAccountsChanged(a, true))
        .catch((err: any) => console.log(err))
        .finally(() => setAccountChangePending(false));

      return;
    }
  };

  /**
   * Deactivates the current account.
   */
  const disconnect = async () => {
    setIsDisconnected(true);
    setCurrentAccount(undefined);
    setProviderName("");
    if (provider?.disconnect) {
      provider.disconnect();
    } else {
      await requests
        .disconnect()
        .then(handleDisconnect)
        .catch((error: any) => console.error(error));
    }
  };

  const handleSwitchNetwork = async () => {
    await requests.switchChain();
  };

  const handleDisconnect = () => {
    setCurrentAccount(undefined);
  };

  const formatBalance = (a: string) => {
    const value = parseInt(a) / Math.pow(10, 18) || 0;
    setBalance(value);
  };

  const sign = async (
    account: string | undefined,
    msg: string,
    pass: string
  ) => {
    const signResult = await requests.sign(account, msg, pass);
    return signResult;
  };

  /**
   * On mount set pending state to true.
   * Check ethereum provider availability and connected accounts
   * Set pending state to false.
   */
  React.useEffect(() => {
    setAccountChangePending(true);
    checkProviderAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If account has been defined set it to state and get balance.
   * add event listener for network changes.
   */
  React.useEffect(() => {
    if (provider) {
      provider.on("chainChanged", () => {
        window.location.reload();
      });
      provider.on("disconnect", () => {
        window.location.reload();
      });
      provider.on("accountsChanged", (accounts: string[]) => {
        window.location.reload();
      });
    }
    if (currentAccount && !prevAccount) {
      handleBalance(currentAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum, currentAccount, prevAccount]);

  return {
    account: currentAccount,
    accountChangePending,
    balance: balance.toFixed(4) || "",
    connect,
    disconnect,
    ethereum,
    insufficientFunds: balance < MINT_PRICE,
    isWrongNetwork,
    switchNetwork: handleSwitchNetwork,
    sign,
  };
};
