import { ethers } from "ethers";
import React from "react";
import { Contract, useContract } from "../hooks/ethereum/useContract";
import { Web3Provider } from "@ethersproject/providers";

interface IContractContext extends Contract {}

export const ContractContext = React.createContext<IContractContext>({
  contract: {} as ethers.Contract,
  hasReceipt: false,
  mintToken: () => null,
  preMint: (amount: number) => null,
  transactionPending: false,
  transactionSuccessful: false,
  transactionUnSuccessful: false,
  resetTransactionState: () => null,
  tokenIds: [],
  enableMinting: () => null,
  saleIsActive: false,
  tokenBalancePending: false,
  currentTokenBalance: 0,
  withDrawEth: (ethereum: Web3Provider, address: string) => null,
  totalSupply: 0,
  getTotalSupply: () => null,
  revealCollection: (uri: string) => null,
  baseUri: undefined as unknown as string,
  setProvenance: (provenanceHash: string) => null,
  provenance: undefined as unknown as string,
});

export const ContractProvider: React.FC = React.memo(({ children }) => {
  const contract = useContract();

  return (
    <ContractContext.Provider value={{ ...contract }}>
      {children}
    </ContractContext.Provider>
  );
});
