import React from "react";
import { IndicatorContentProps } from "./types";

function WrongNetwork({ closeModal }: IndicatorContentProps) {
  return (
    <div className="relative w-full h-full flex flex-col items-center ">
      <button
        onClick={closeModal}
        className="absolute top-0 right-0 flex justify-center items-center p-4"
      >
        <img
          src={require("../../../assets/images/close.png")}
          alt="close"
          className="w-3 object-contain"
        />
      </button>
      <img
        src={require("../../../assets/images/wrong-network.png")}
        alt="wrong-network"
        className="w-64 object-contain mt-16"
      />
      <p className="font-semibold text-2xl my-2">Wrong Network</p>
      <p className="font-semibold text-xs">Switch to Ethereum Mainnet</p>
      <button className="text-center absolute bottom-11 font-semibold text-xs bg-black text-greenery w-52 h-11 rounded-[4px] hover:opacity-75">
        Switch Network
      </button>
    </div>
  );
}

export default WrongNetwork;
