// Team
import ArtDirector from "assets/images/team/artdirector.png";
import Blockchain from "assets/images/team/blockchain.png";
import Frontend from "assets/images/team/frontend.png";
import Gamemaker from "assets/images/team/gamemaker.png";
import Graphicdesigner from "assets/images/team/graphicdesigner.png";
import Illustrator from "assets/images/team/illustrator.png";
import Intern from "assets/images/team/intern.png";
import Multitasker from "assets/images/team/multitasker.png";
import UIdesigner from "assets/images/team/uidesigner.png";

export const DISCORD_PAGE_HREF = "http://discord.gg/omakasea";
export const MASATOSHI_CONTRACT_ADDRESS =
  "0xF01FAB0B28dC0C2adFc9a437cB320cfE813f6C2B";

export const WalletOptions = [
  { src: "metamask.png", text: "Metamask" },
  { src: "safepal.png", text: "SafePal" },
  { src: "trust-wallet.png", text: "Trust Wallet" },
  { src: "token-pocket.png", text: "Token Pocket" },
  {
    src: "wallet-connect.png",
    text: "Wallet Connect",
  },
];

export const TextRepeats = [0, 1, 2, 3, 4, 5, 6, 7];
export const ImageRepeats = [0, 1, 2, 3, 4, 0, 1, 2, 3, 4];

export const Teams = [
  {
    image: Gamemaker,
    text: "Wants to change the world, but got high and forgot about it.",
  },
  {
    image: Blockchain,
    text: "The Blockchain Dev  who quit Metamask to work on Prepacked.",
  },
  {
    image: Multitasker,
    text: "The Multitasker  always on call, makes sure everyone showers and eat.",
  },
  {
    image: Intern,
    text: "The Intern  Illustrator  who got hired, thanks for all the accessories.",
  },
  {
    image: Frontend,
    text: "The Front End Dev  who dates the intern, slightly naughty and makes sure everything is neat and thighty.",
  },
  {
    image: ArtDirector,
    text: "The Art Director  who’s always sick but still creates the sickest shit.",
  },
  {
    image: Graphicdesigner,
    text: "The Graphic Designer always on stand by, he only drink's ice coffees.",
  },
  {
    image: Illustrator,
    text: "The Illustrator  who as 2 jobs and a shitty internet connection and still managed to create all Prepacked Characters.",
  },
  {
    image: UIdesigner,
    text: "The UI  Designer , give him something to smoke if you want him to get creative.",
  },
];
