import { ButtonProps } from "./types";

function SocialButton({ name, onClick, classname }: ButtonProps) {
  return (
    <button onClick={onClick} className={`${classname} hover:opacity-75`}>
      <img
        className="object-contain h-5"
        src={require(`../../../assets/images/${name}.png`)}
        alt={name}
      />
    </button>
  );
}

export default SocialButton;
