import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";
import Mint from "./Mint";
import MyToy from "./MyToy";
import { MainWalletProps } from "./types";

function MainWallet({
  mainWalletTabIndex,
  handleDisconnect,
  account,
}: MainWalletProps) {
  const [totalMint, setTotalMint] = useState(1);

  const toys = [
    { name: "Prepacked #1" },
    { name: "Prepacked #2" },
    { name: "Prepacked #3" },
    { name: "Prepacked #4" },
    { name: "Prepacked #5" },
    { name: "Prepacked #6" },
  ];

  return (
    <div className="flex flex-col h-[600px] md:w-[352px] rounded-[12px]">
      <div className="flex flex-row justify-between items-center px-7 pb-4 md:py-4 md:bg-black md:rounded-t-[12px]">
        <div className="flex w-32 flex-col">
          <p className="font-semibold text-[10px] md:text-white">
            Wallet Address
          </p>
          <p className="text-[10px] text-ellipsis overflow-hidden md:text-white">
            {account}
          </p>
        </div>
        <button
          onClick={handleDisconnect}
          className="font-semibold text-[9px] hover:opacity-75 focus:opacity-75 p-2 md:text-white"
        >
          Disconnect
        </button>
      </div>
      <hr className="border border-dugong mb-6 opacity-40" />
      <Tab.Group defaultIndex={mainWalletTabIndex}>
        <Tab.List className={"ml-7"}>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`text-black font-semibold text-lg w-20 h-8 opacity-40 ${
                  selected && "opacity-100 border rounded-[18px] border-black"
                } hover:opacity-100 mr-8 select-none`}
              >
                Mint
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`text-black font-semibold text-lg w-28 h-8 opacity-40 ${
                  selected && "opacity-100 border rounded-[18px] border-black"
                } hover:opacity-100 select-none`}
              >
                My Toys
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className={"mt-16"}>
          <Tab.Panel>
            <Mint setTotalMint={setTotalMint} totalMint={totalMint} />
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex flex-row overflow-x-scroll w-screen md:w-[352px] pl-7 scroll-pl-7 snap-x">
              {toys.map(({ name }, index) => {
                return <MyToy key={index} title={name} />;
              })}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default MainWallet;
