import { useContext } from "react";
import { WalletContext } from "tools/context/WalletContext";
import { WalletContainerProps } from "./types";
import Wallet from "./Wallet";

function WalletContainer({
  isOpen,
  onClose,
  mainWalletTabIndex,
}: WalletContainerProps) {
  const {
    // balance,
    // insufficientFunds,
    disconnect,
    account,
    // ethereum,
    connect,
    accountChangePending,
    // isWrongNetwork,
    // switchNetwork,
  } = useContext(WalletContext);

  // const [isAccountModal, setIsAccountModal] = useState(true);
  // const [indicatorType, setIndicatorType] = useState<IndicatorType>("SUCCESS");

  const isAccountModal = true;
  const indicatorType = "SUCCESS";
  const handleConnect = (isMetaMask: boolean) => {
    connect(isMetaMask);
  };

  const handleDisconnect = () => {
    disconnect();
    handleClose();
    // const path = location.pathname;
    // if (path !== "/home" && path !== "/kitchen") {
    //   navigate("/home", { replace: true });
    // }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Wallet
      isOpen={isOpen}
      onClose={onClose}
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
      account={account}
      accountChangePending={accountChangePending}
      mainWalletTabIndex={mainWalletTabIndex}
      isAccountModal={isAccountModal}
      indicatorType={indicatorType}
    />
  );
}

export default WalletContainer;
