import { useState } from "react";
import { WalletOptions } from "tools/constants";
import { SelectWalletProps } from "./types";
import WalletOption from "./WalletOption";

function SelectWallet({
  handleConnect,
  closeModal,
  isDesktop,
}: SelectWalletProps) {
  const [selectedWallet, setSelectedWallet] = useState("");

  const connect = () => {
    handleConnect(selectedWallet === "Metamask" ? true : false);
    setSelectedWallet("");
  };

  return (
    <div className="px-9 py-10 w-full">
      {isDesktop && (
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 flex justify-center items-center p-4"
        >
          <img
            src={require("../../../assets/images/close.png")}
            alt="close"
            className="w-3 object-contain"
          />
        </button>
      )}
      <div className="flex flex-col items-center justify-start">
        <h1
          className={`font-semibold text-2xl mb-9 w-full text-left ${
            isDesktop && "mt-4"
          }`}
        >
          Select Wallet
        </h1>
        {WalletOptions.map(({ src, text }, index) => (
          <WalletOption
            key={index}
            source={src}
            title={text}
            onClick={setSelectedWallet}
            isSelected={text === selectedWallet}
          />
        ))}
        <button
          onClick={connect}
          className="text-greenery font-bold bg-black rounded-[4px] h-11 w-[205px] mt-7"
        >
          Connect
        </button>
      </div>
    </div>
  );
}

export default SelectWallet;
