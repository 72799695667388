import { MintProps } from "./types";
import PriceShape from "assets/images/price-shape.png";
import Slider, { Handle, SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { useContext } from "react";
import { ContractContext } from "tools/context/ContractContext";

function Mint({ setTotalMint, totalMint }: MintProps) {
  const { mintToken } = useContext(ContractContext);
  const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="slider-tooltip"
        overlay={`${value}`}
        placement="top"
        visible={dragging}
        key={index}
        overlayInnerStyle={{
          backgroundColor: "#9239DF",
          caretColor: "blue",
          width: "28px",
          height: "21px",
          fontFamily: "aeonik",
          paddingTop: 4,
        }}
        defaultVisible={true}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  return (
    <div className="flex flex-col w-screen md:w-[352px] px-7 justify-center items-center">
      <div
        className="bg-local w-[153px] h-[153px] flex flex-col items-center justify-center text-center font-biffbamboom"
        style={{
          backgroundImage: `url(${PriceShape})`,
          backgroundSize: "cover",
        }}
      >
        <p className="text-[15px]">ONLY</p>
        <p className="text-[28px]">0.299</p>
        <p className="text-[17px]">ETH</p>
      </div>
      <p className="font-poppins text-xs mt-4 text-center">
        Order now and receive… <br />
        Fuck off just mint.
      </p>
      <div className="w-64 mt-12">
        <Slider
          trackStyle={{ backgroundColor: "black" }}
          onChange={(value) => setTotalMint(value)}
          handleStyle={{
            borderColor: "white",
            height: "15px",
            width: "15px",
            backgroundColor: "#9239DF",
            boxShadow: `0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)`,
          }}
          min={1}
          max={10}
          handle={handle}
        />
      </div>
      <button
        onClick={mintToken}
        className="text-center absolute bottom-11 font-semibold text-xs bg-black text-greenery w-52 h-11 rounded-[4px] hover:opacity-75"
      >
        {`Mint ${totalMint} Prepacked`}
      </button>
    </div>
  );
}

export default Mint;
