import React from "react";
import { MyToyProps } from "./types";

function MyToy({ title }: MyToyProps) {
  return (
    <div className="bg-white snap-start flex flex-col mr-3 rounded-lg border">
      <p className="w-[216px] h-64 mx-4 mt-4 mb-4 flex bg-black opacity-[0.08] rounded-[4px]">
        {/* {toy} */}
      </p>
      <p className="font-semibold text-xs mb-4 mx-4">{title}</p>
    </div>
  );
}

export default MyToy;
