import { AppContainer } from "layouts/AppContainer";
import AppRoutes from "router/AppRoutes";
import { ContractProvider } from "tools/context/ContractContext";
import { ModalProvider } from "tools/context/ModalContext";
import { WalletProvider } from "tools/context/WalletContext";
import "./App.css";

function App() {
  return (
    <WalletProvider>
      <ContractProvider>
        <ModalProvider>
          <AppContainer>
            <AppRoutes />
          </AppContainer>
        </ModalProvider>
      </ContractProvider>
    </WalletProvider>
  );
}

export default App;
