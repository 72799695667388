import React from "react";
import { WalletOptionProps } from "./types";

function WalletOption({
  onClick,
  source,
  title,
  isSelected,
}: WalletOptionProps) {
  return (
    <button
      onClick={() => onClick(title)}
      className={`mb-3 flex flex-row h-14 border ${
        isSelected && "border-black"
      } w-full items-center rounded-[4px]`}
    >
      <img
        className="w-6 object-contain ml-6 mr-4"
        src={require(`../../../assets/images/${source}`)}
        alt={title}
      />
      <p className="font-medium text-base">{title}</p>
    </button>
  );
}

export default WalletOption;
