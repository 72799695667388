import WalletContainer from "components/modals/WalletContainer";
import React from "react";
// import NoReceiptModal from "../../pages/Modals/NoReceiptModal/NoReceiptModal";
// import { TransactionPendingModal } from "../../pages/Modals/TransactionPendingModal/TransactionPendingModal";
import {
  ModalController,
  useModalController,
} from "../hooks/useModalController";

interface IModalContext extends ModalController {}

export const ModalContext = React.createContext<IModalContext>(
  {} as IModalContext
);

export const ModalProvider: React.FC = ({ children }) => {
  const modalController = useModalController();
  return (
    <ModalContext.Provider value={{ ...modalController }}>
      {children}
      <WalletContainer
        isOpen={modalController.walletModal.isOpen}
        onClose={modalController.walletModal.onClose}
        mainWalletTabIndex={modalController.mainWalletTabIndex}
      />
    </ModalContext.Provider>
  );
};
