import Footer from "components/molecules/Footer";
import Header from "components/molecules/Header";
import React from "react";

export const AppContainer: React.FC = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
