import React from "react";
import { IndicatorContentProps } from "./types";

function Error({ closeModal }: IndicatorContentProps) {
  return (
    <div className="relative w-full h-full flex flex-col items-center">
      <button
        onClick={closeModal}
        className="absolute top-0 right-0 flex justify-center items-center p-4"
      >
        <img
          src={require("../../../assets/images/close.png")}
          alt="close"
          className="w-3 object-contain"
        />
      </button>
      <img
        src={require("../../../assets/images/error.png")}
        alt="wrong-network"
        className="w-40 object-contain mt-24"
      />
      <p className="font-semibold text-2xl mt-7 text-center">Something<br />went wrong</p>
      <p className="text-center absolute bottom-11 font-semibold text-xs">
        Check your Wallet for details.
      </p>
    </div>
  );
}

export default Error;
