import { ButtonProps } from "./types";

function TextButton({ name, onClick, classname }: ButtonProps) {
  return (
    <button onClick={onClick} className={`${classname} hover:opacity-75`}>
      <p className="text-white font-sans text-[10px] tracking-[2px] font-medium">{name}</p>
    </button>
  );
}

export default TextButton;
