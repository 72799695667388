import ReactModal from "react-modal";
import useDeviceDetect from "tools/hooks/useDeviceDetect";
import { AccountProps } from "./types";
import MainWallet from "./WalletContent/MainWallet";
import SelectWallet from "./WalletContent/SelectWallet";

function Account({
  closeModal,
  isClosing,
  isOpen,
  handleConnect,
  handleDisconnect,
  mainWalletTabIndex,
  account,
  onClose,
}: AccountProps) {
  const { isMobile } = useDeviceDetect();

  const renderContent = () => {
    return account ? (
      <MainWallet
        mainWalletTabIndex={mainWalletTabIndex}
        handleDisconnect={handleDisconnect}
        account={account!}
      />
    ) : (
      <SelectWallet
        handleConnect={handleConnect}
        closeModal={isMobile ? closeModal : onClose}
        isDesktop={!isMobile}
      />
    );
  };

  const Desktop = () => (
    <ReactModal
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      ariaHideApp={false}
      className={`bg-white flex w-[352px] h-[600px] absolute left-0 right-0 top-5 bottom-0 rounded-xl mx-auto my-auto z-50`}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.75)",
        },
      }}
      isOpen={isOpen}
    >
      {renderContent()}
    </ReactModal>
  );

  const Mobile = () => (
    <ReactModal
      ariaHideApp={false}
      className={`bg-white flex flex-col bottom-0 absolute w-full mt-12 rounded-t-2xl z-50
      ${
        !isClosing
          ? "animate__animated animate__fast animate__slideInUp"
          : "animate__animated animate__fast animate__slideOutDown"
      }`}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.75)",
        },
      }}
      isOpen={isOpen}
    >
      <button
        onClick={closeModal}
        className="bg-white h-12 w-full flex justify-center items-center rounded-t-2xl"
      >
        <img
          src={require("../../assets/images/arrow-down.png")}
          alt="arrow-down"
          className="object-contain w-6 opacity-20"
        />
      </button>
      {renderContent()}
    </ReactModal>
  );
  return isMobile ? <Mobile /> : <Desktop />;
}

export default Account;
