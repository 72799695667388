import { ButtonProps } from "./types";

function PrimaryButton({ onClick, name, classname, theme }: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`hover:opacity-75 ${
        theme === "black" ? "bg-black" : "bg-[#D0FF9A]"
      } rounded-lg w-[215px] lg:w-64 py-[11px] lg:py-[13px] ${classname}`}
    >
      <p
        className={`font-bold tracking-wider text-[20px] ${
          theme === "black" ? "text-[#D0FF9A]" : "text-black"
        } lg:text-2xl`}
      >
        {name}
      </p>
    </button>
  );
}

export default PrimaryButton;
