import React from "react";
import ReactModal from "react-modal";
import useDeviceDetect from "tools/hooks/useDeviceDetect";
import Background from "assets/images/minting-bg.png";
import SuccessBackground from "assets/images/success-background.png";
import { IndicatorProps } from "./types";

export const Indicator = ({
  children,
  closeModal,
  isClosing,
  isOpen,
  onClose,
  withBackgroundImage,
  indicatorType,
}: IndicatorProps) => {
  const isMobile = useDeviceDetect();

  const backgroundImage =
    indicatorType === "MINTING"
      ? Background
      : indicatorType === "SUCCESS"
      ? SuccessBackground
      : "";

  const Desktop = () => (
    <ReactModal
      ariaHideApp={false}
      className={`${
        !withBackgroundImage && "bg-white"
      } flex w-[352px] h-[600px] absolute left-0 right-0 top-5 bottom-0 rounded-xl mx-auto my-auto`}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.75)",
        },
        content: withBackgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
            }
          : {},
      }}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );

  const Mobile = () => (
    <ReactModal
      ariaHideApp={false}
      className={`${
        !withBackgroundImage && "bg-white"
      } flex flex-col items-center justify-center rounded-2xl max-w-[314px] h-[600px] absolute top-0 left-0 bottom-0 right-0 m-auto`}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.75)",
        },
        content: withBackgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
            }
          : {},
      }}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );

  return isMobile ? <Mobile /> : <Desktop />;
};

export default Indicator;
