import { useContext } from "react";
import { WalletContext } from "tools/context/WalletContext";
import { ButtonProps } from "./types";
import WalletImage from "assets/images/wallet.png";

function ConnectWallet({ onClick }: ButtonProps) {
  const { account } = useContext(WalletContext);

  return (
    <button
      onClick={onClick}
      className="flex flex-row items-center justify-center border border-greenery hover:opacity-75 rounded-xl lg:rounded-2xl h-[22px] w-[90px] md:w-32 md:h-8"
    >
      <img
        className="object-contain w-[8px] h-[7px] md:w-[10px] md:h-[9px] mr-2"
        src={WalletImage}
        alt="wallet"
      />
      <p className="text-white text-[8px] lg:text-[10px] font-aeonik md:font-bold">
        {account ? "Connected" : "Connect Wallet"}
      </p>
    </button>
  );
}

export default ConnectWallet;
