import React from "react";
import MyToy from "./MyToy";
import { IndicatorContentProps } from "./types";

function Success({ closeModal }: IndicatorContentProps) {
  // const toys = [
  //   { name: "Prepacked #1" },
  //   { name: "Prepacked #2" },
  //   { name: "Prepacked #3" },
  //   { name: "Prepacked #4" },
  //   { name: "Prepacked #5" },
  //   { name: "Prepacked #6" },
  // ];

  const toys = [{ name: "Prepacked #1" }];
  return (
    <div className="relative w-full h-full flex flex-col items-center">
      <button
        onClick={closeModal}
        className="absolute top-0 right-0 flex justify-center items-center p-4"
      >
        <img
          src={require("../../../assets/images/close-white.png")}
          alt="close"
          className="w-3 object-contain"
        />
      </button>
      <p className="font-semibold text-3xl mt-16 text-center text-greenery">
        Congratulations!
      </p>
      <p className="text-center font-semibold text-[15px] text-white mb-[55px]">
        Enjoy your new toy.
      </p>
      {toys.length > 1 ? (
        <div className="flex flex-row overflow-x-scroll w-screen md:w-[352px] pl-7 scroll-pl-7 snap-x">
          {toys.map(({ name }, index) => {
            return <MyToy key={index} title={name} />;
          })}
        </div>
      ) : (
        <div>
          <MyToy title={toys[0].name} />
        </div>
      )}
      <p className="text-center absolute bottom-11 font-semibold text-sm text-white">
        {toys[0].name}
      </p>
    </div>
  );
}

export default Success;
