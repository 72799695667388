import { ButtonProps } from "./types";
import Logo from "assets/images/logo.png";

function AppLogo({ onClick }: ButtonProps) {
  return (
    <button onClick={onClick} className="hover:opacity-75">
      <img className="object-contain h-6 md:h-8" src={Logo} alt="logo" />
    </button>
  );
}

export default AppLogo;
