import MintShape from "assets/images/mint-shape.png";

function Minting() {
  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center">
      <div
        className="bg-local w-56 h-56 flex items-center justify-center"
        style={{
          backgroundImage: `url(${MintShape})`,
          backgroundSize: "contain",
        }}
      >
        <p className="text-greenery font-semibold text-2xl">Minting</p>
      </div>
      <p className="text-center absolute bottom-11 font-semibold text-xs">
        Keep your browser
        <br />
        open the entire time.
      </p>
    </div>
  );
}

export default Minting;
