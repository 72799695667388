import { lazy, Suspense, useContext, useState } from "react";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { ModalContext } from "tools/context/ModalContext";

const Hero = lazy(() => import("components/molecules/Hero"));
const Description = lazy(() => import("components/molecules/Description"));
const Showcase = lazy(() => import("components/molecules/Showcase"));
const Passport = lazy(() => import("components/molecules/Passport"));
const Traits = lazy(() => import("components/molecules/Traits"));
const Story = lazy(() => import("components/molecules/Story"));
const Game = lazy(() => import("components/molecules/Game"));
const Team = lazy(() => import("components/molecules/Team"));
const Gift = lazy(() => import("components/molecules/Gift"));

function Home() {
  const { walletModal } = useContext(ModalContext);
  const [isBookOpen, setIsBookOpen] = useState(false);

  const mint = () => {
    walletModal.onOpen();
  };

  return (
    <div className="overflow-hidden origin-center">
      {/* hero section */}
      <Suspense fallback={<div />}>
        <Hero mint={mint} />
      </Suspense>

      {/* Prepacked Description */}
      <Suspense fallback={<div />}>
        <Description />
      </Suspense>

      {/* running text section */}
      <Suspense fallback={<div />}>
        <Showcase />
      </Suspense>

      {/* passport section */}
      <Suspense fallback={<div />}>
        <Passport />
      </Suspense>

      {/* Character & Traits */}
      <Suspense fallback={<div />}>
        <Traits />
      </Suspense>

      {/* Flipbook Section */}
      <Suspense fallback={<div />}>
        <Story
          isOpen={isBookOpen}
          setIsOpen={() => setIsBookOpen(!isBookOpen)}
        />
      </Suspense>

      {/* The Game */}
      <Suspense fallback={<div />}>
        <Game />
      </Suspense>

      {/* The Team */}
      <Suspense fallback={<div />}>
        <Team />
      </Suspense>

      {/* Gift */}
      <Suspense fallback={<div />}>
        <Gift />
      </Suspense>

      <div className="mt-16 lg:mt-32 mb-[131px] flex flex-1 justify-center items-center">
        <PrimaryButton onClick={mint} name="Mint Now" theme="black" />
      </div>
    </div>
  );
}

export default Home;
